import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import LbMain from '@/components/LbMain.vue'
import Drawer from '@/components/Drawer.vue'
import Pagination from '@/components/Pagination.vue'
import FinishText from '@/components/FinishText.vue'
import CommonTable from '@/components/CommonTable.vue'
import CommonFilter from '@/components/CommonFilter.vue'
import CommonHead from '@/components/CommonHead.vue'
import CommonTabs from '@/components/CommonTabs.vue'
import CommonPrompt from '@/components/CommonPrompt.vue'
import CenterArea from '@/components/CenterArea.vue'
import Uploader from '@/components/Uploader.vue'
import Pic from "@/components/Pic.vue";
import CommonConfirm from "@/components/CommonConfirm.vue";
import CommonEmpty from "@/components/CommonEmpty.vue";
import 'normalize.css'
import 'element-plus/dist/index.css'
import './styles/element/index.scss'
import './styles/common.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import filters from "@/utils/filter";


/* 请求 */
import * as axios from "@/service/index.js";
import store ,{ setupStore}from "@/store";



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 注册全局组件
app.component('LbMain', LbMain)
app.component('Drawer', Drawer)
app.component('Pagination', Pagination)
app.component('FinishText', FinishText)
app.component('CommonTable', CommonTable)
app.component('CommonFilter', CommonFilter)
app.component('CommonHead', CommonHead)
app.component('CommonTabs', CommonTabs)
app.component('CommonPrompt', CommonPrompt)
app.component('CenterArea', CenterArea)
app.component('Uploader', Uploader)
app.component('Pic', Pic)
app.component('CommonConfirm',CommonConfirm)
app.component('CommonEmpty',CommonEmpty)
// 挂载
app.config.globalProperties.$service = axios.jyRequest;
app.config.globalProperties.$imgBase = axios.IMG_URL;
app.config.globalProperties.$basUrl = axios.BaseURL;

app.config.globalProperties.$filters=filters;

// 挂载
app.use(ElementPlus, {
  locale: zhCn,
  size: 'large',
}).use(store).use(router).mount('#app')
setupStore()
