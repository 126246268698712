<template>
  <el-dialog
    :model-value="visible"
    :title="title"
    :width="width"
    :before-close="handleClose"
    class="dialog-modal"
  >
    <template #default>
      <div class="dialog-body">
        <slot name="default"></slot>
      </div>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <slot name="footer"></slot>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
// props
const props = defineProps({
  // 是否显示
  visible: {
    type: Boolean,
    default: false,
  },
  // 标题
  title: {
    type: String,
    default: "",
  },
  // 宽度
  width: {
    type: String,
    default: "30%",
  },
});

// emit
const emit = defineEmits(["before-close"]);
const handleClose = () => {
  emit("before-close");
};
</script>
<style>
.el-dialog__header {
  margin-right: 0;
  padding: 12px 24px;
  border-bottom: solid #e6e6e6 1px;
}
.el-dialog__title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
}
.el-dialog__body {
  padding: 0 !important;
}
.el-dialog__footer {
  padding: 0 !important;
}
.el-dialog__headerbtn {
  font-size: 20px !important;
  top: -2px;
}
</style>
<style lang="scss" scoped>
.dialog-modal {
  .dialog-body {
    padding: 24px 24px 8px;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
  }
}
</style>