<script setup>
import {reactive} from "vue";

const props = defineProps(['title', 'cancelText', 'confirmText','confirmFn']);
const data = reactive(({
  visible: false,
  loading:false
}))
const $emit=defineEmits(['handleHideModal'])
const handleHideModal = () => {
  data.visible = false;
  $emit('handleHideModal')
}
const handleConfirm=async ()=>{
  if(props.confirmFn){
    data.loading=true
    try {
      await props.confirmFn();
      data.loading=false
    }catch (e) {
      data.loading=false;
      return
    }
  }
  handleHideModal()
}
const handleShowModal = () => {
  data.visible = true
}

defineExpose({
  handleShowModal,
  handleHideModal
})
</script>

<template>
  <el-dialog width="480px" v-model="data.visible" class="common-confirm">
    <template #header>
      <span>{{ title }}</span>
    </template>
    <div class="el-dialog__body--custom">
      <slot></slot>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <slot name="footer">
          <el-button v-if="cancelText" @click="handleHideModal">{{ cancelText }}</el-button>
          <el-button v-if="confirmText" :loading="data.loading" @click="handleConfirm" type="primary">{{ confirmText }}</el-button>
        </slot>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss">
.common-confirm {
  .el-dialog__header {
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color:rgba(0, 0, 0, 0.9);
    font-weight: bold;
    margin:0;
  }
  .el-dialog__body--custom{
    padding:24px 24px 8px;
  }
  .el-dialog__headerbtn{
    width: 48px;
    height: 48px;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-dialog__footer{
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding:0 20px!important;
    .el-button{
      width: 60px;
      height: 32px;
    }
  }
}
</style>