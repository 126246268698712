<template>
  <div class="picWrap" :style="{ width: size, height: size }" :class="{'picWrap-modal':hasModal}">
    <el-image class="pic" hide-on-click-modal :style="{ width: size, height: size }" preview-teleported
              :preview-src-list="previewList" :src="loadImg(url)"/>
    <img
        v-if="showDel"
        @click="handleDel"
        :src="require('@/assets/icon_del.png')"
        class="icon-del"
        :style="{ width: delSize, height: delSize }"
        alt=""
    />
  </div>
</template>

<script setup>
import {loadImg} from "@/utils/file";
import {computed} from "vue";

// props
const props = defineProps({
  // 图片URL
  url: {
    type: String,
    default: "",
  },
  // 大小
  size: {
    type: String,
    default: "98px",
  },
  // 删除图标大小
  delSize: {
    type: String,
    default: "20px",
  },
  // 是否展示删除
  showDel: {
    type: Boolean,
    default: true,
  },
  hasModal: {
    type: Boolean,
    default: false
  },
  previewSrcList: {
    default: null
  }
});

const previewList = computed(() => {
  if (props.previewSrcList) {
    if (typeof props.previewSrcList === 'string') {
      return [loadImg(props.previewSrcList)]
    }
    if (Array.isArray(props.previewSrcList)) {
      return props.previewSrcList.map(item => {
        return loadImg(item)
      })
    }
  }
  return [loadImg(props.url)]
})
// emit
const emit = defineEmits(["del"]);
const handleDel = () => {
  emit("del");
};
</script>

<style lang="scss" scoped>
.picWrap {
  position: relative;
  width: 98px;
  height: 98px;
  border-radius: 2px;
  overflow: hidden;

  &-modal {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .icon-del {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .pic {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    position: relative;
  }
}
</style>