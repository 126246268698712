<template>
  <el-drawer
    :model-value="visible"
    :title="title"
    :direction="direction"
    :size="size"
    :before-close="handleClose"
    destroy-on-close
    :with-header="withHeader"
  >
    <div class="drawer">
      <div class="drawer_main">
        <slot></slot>
      </div>
      <div v-if="hasBtn" class="drawer_footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </el-drawer>
</template>
<script setup>
// props
defineProps({
  // 是否显示
  visible: {
    type: Boolean,
    default: false,
  },
  // 标题
  title: {
    type: String,
    default: "",
  },
  // 方向
  direction: {
    type: String,
    default: "rtl",
  },
  // 长度
  size: {
    type: [String, Number],
    default: "30%",
  },
  // 是否有底部
  hasBtn: {
    type: Boolean,
    default: true,
  },
  //是否有顶部
  withHeader:{
    type:Boolean,
    default:true
  }
});

// 关闭前调用
const emit = defineEmits(["close"]);
const handleClose = (done) => {
  emit("close");
};
</script>
<style lang="scss">
.el-drawer__header {
  margin-bottom: 0 !important;
  padding: 24px 48px !important;
  border-bottom: solid #eaeaea 1px;
  box-sizing: border-box;
  .el-drawer__title {
    line-height: 28px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
  }
  .el-drawer__close-btn {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
}
.el-drawer__body {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.drawer_main {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  // 定义滚动条轨道
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // 定义滑块
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
}
.drawer_footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px 48px;
  border-top: solid #eaeaea 1px;
}
</style>