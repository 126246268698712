const content = [
  {
    path: '/content',
    component: () => import('@/layout/index.vue'),
    meta: {
      title: '内容配置',
      isMenu: true,
      icon: 'icon_content.png',
      showChild:true
    },
    children: [
      {
        path: '/content/firstScreen',
        component: () => import('@/views/firstScreen/index.vue'),
        meta: {
          title: '开屏配置',
          keepAlive: false,
          isMenu: true,
        },
      },
      {
        path: '/operation/home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '首页配置',
          keepAlive: false,
          isMenu: true,
        },
      },
      {
        path: '/operation/service',
        component: () => import('@/views/service/index.vue'),
        meta: {
          title: '服务配置',
          keepAlive: false,
          isMenu: true,
        },
      },
    ],
  },
]

export default content