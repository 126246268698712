<template>
  <div class="pagination-wrap">
    <div v-if="total > pageSize" class="pagination-real">
      <el-pagination
        :current-page="pageIndex"
        :page-size="pageSize"
        :total="total"
        :layout="layout"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <FinishText v-if="total&&total<=pageSize" :text="finishText" />
  </div>
</template>
<script setup>
// props
defineProps({
  // 分页信息
  pageIndex: {
    type: Number,
    default: 1,
  },
  pageSize: {
    type: Number,
    default: 10,
  },
  // 总条数
  total: {
    type: Number,
    default: 0,
  },
  // 元素
  layout: {
    type: String,
    default: "total, prev, pager, next, sizes, jumper",
  },
  // 暂无更多文字
  finishText: {
    type: String,
    default: "— 暂无更多数据 —",
  },
});

// emit
const emit = defineEmits(["size-change", "current-change"]);
// 分页大小
const handleSizeChange = (e) => {
  emit("size-change", e);
};
// 当前页
const handleCurrentChange = (e) => {
  emit("current-change", e);
};
</script>
<style lang="scss" scoped>
.pagination-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
:deep(.pagination-real) {
  padding: 16px 0;

  .el-pager li,
  .btn-prev,
  .btn-next {
    --el-pagination-button-height: 34px;
    --el-pagination-button-bg-color: #fff;
    --el-pagination-button-color: rgba(0, 0, 0, 0.8);
    padding: 0 7px;
    min-width: 34px;
    border: solid #eaeaea 1px;
    border-radius: 2px;
    &:hover,
    &.is-active {
      --el-color-primary: #fff;
      --el-color-white: #c81033;
      border-color: #c81033;
    }
  }

  .btn-prev,
  .btn-next {
    &:disabled {
      --el-text-color-placeholder: #eaeaea;
      --el-disabled-bg-color: #fff;
      border: solid #eaeaea 1px;
    }
  }
}
</style>