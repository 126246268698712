class Cache {
    setItem(key,value){
        localStorage.setItem(key,JSON.stringify(value))
    }
    getItem(key){
        try {
          return JSON.parse(localStorage.getItem(key))
        } catch (error) {}
        return '';
    }
    clear(){
        localStorage.clear()
    }
}

export default new Cache()