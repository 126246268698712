<template>
  <!-- 筛选组件 -->
  <div>
    <CommonFilter :filterConfig="condition" :filterData="condition.data" @query="page.pageIndex=1 && doQuery(true)"/>
    <div class="table-content" :style="{borderBottom:page.total?'1px #eaeaea solid':'none'}">
      <!-- 表格 -->
      <el-table ref="tableRef" @sort-change="handleSort" class="table-real"  element-loading-background="#fff" v-loading="loading" :data="tableData"
                @expand-change="handleExpandChange" row-key="id">
        <template #empty>

          <common-empty :icon="emptyType?empty:empty1" v-show="!loading">
            <slot name="empty" :emptyType="emptyType">{{ emptyType ? '暂无任何信息' : '暂无符合条件的搜索结果' }}</slot>
          </common-empty>
          <div v-show="!loading"></div>
        </template>
        <el-table-column v-if="tableConfig.showExpand" type="expand" width="1">
          <template #default="props">
            <slot name="expand" :row="props.row"/>
          </template>
        </el-table-column>
        <el-table-column v-for="item in tableConfig.columns" :key="item.prop" v-bind="item">
          <template #default="scope">
            <slot v-if="item.type === 'slot'" :name="item.prop" :row="scope.row"></slot>
            <span v-else>{{ item.getLabel ? item.getLabel(scope.row) : scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="tableConfig.opt && tableConfig.opt.buttons" v-bind="tableConfig.opt.attrs">
          <template #default="scope">
            <div class="btn-group">
              <template v-for="(item, index) in tableConfig.opt.buttons" :key="index">
                <el-button
                    v-if="handleShowBtn(item, scope)"
                    @click="handleClickBtn(item, scope.row)"
                    :disabled="
                  handleDisabledBtn(item.disabled, {
                    ...scope.row,
                    $index: scope.$index,
                  })
                "
                    :type="item.type || 'primary'"
                    link
                >{{ handleText(item.text, scope.row) }}
                </el-button
                >
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination v-bind="page" @current-change="onCurrentChange" @size-change="onSizeChange"/>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import {isEmpty, every} from "lodash";
import empty from '@/assets/empyt.png'
import empty1 from '@/assets/empty_1.png'

const params=ref({})
const emptyType = computed(() => {
  if (typeof params.value === 'object' && params.value) {
    Object.keys(params.value).forEach(key => {
      if (params.value[key] === '全部') {
        delete params.value[key]
      }
    });
    return every(params.value, isEmpty)
  }
  return true
})
const handleSort = (e) => {
  emit('sort-change', e)
}
const tableData = reactive([]);
// props
const props = defineProps({
  /**
   * 查询数据的方法，当分页数据发生变化，就会触发
   */
  query: {
    type: Function,
  },
  /**
   * 定义查询条件的列表
   */
  condition: {
    type: Object,
    default() {
      return {
        // 是否显示
        show: true,
        gutter: 12,
        // 条件字段
        filterList: [],
        data: {}
      }
    }
  },
  // 表格配置
  tableConfig: {
    type: Object,
    require: true,
  },
});

// emit
const emit = defineEmits(["current-change", "size-change", "expand-change", 'sort-change']);
const page = reactive({
  total: 0,
  pageIndex: 1,
  pageSize: 15,
})
const onCurrentChange = (e) => {
  emit("current-change", e);
  page.pageIndex = e;
  doQuery();
};
const onSizeChange = (e) => {
  emit("size-change", e);
  page.pageSize = e;
  page.pageIndex = 1;
  doQuery();
};

onMounted(() => {
  doQuery();
})
const loading = ref(false)
const doQuery = (type) => {
  if (type) {
    page.total = 0;
    page.pageIndex = 1
  }
  // let page = props.pageInfo;
  loading.value = true
  params.value=JSON.parse(JSON.stringify(props.condition.data))
  props.query({start: (page.pageIndex - 1) * page.pageSize, limit: page.pageSize}).then(data => {
    page.total = data.total;
    // page.pageIndex = Math.ceil(page.total / page.pageSize);
    tableData.length = 0;
    const list = data.data || []
    tableData.push(...list);
    loading.value = false
  }).catch(() => {
    loading.value = false
  });
}
// 是否"展示"按钮
const handleShowBtn = (item, scope) => {
  return (
      (item.show && item.show({...scope.row, $index: scope.$index})) ||
      !item.show
  );
};

// 是否"禁用"按钮
const handleDisabledBtn = (disabled = false, row) => {
  if (typeof disabled === "boolean") {
    return disabled;
  }
  if (typeof disabled === "function") {
    return disabled(row);
  }
  return Boolean(disabled);
};

// "点击"按钮
const handleClickBtn = (acItem, data) => {
  let {msg, tips, confirmButtonText, cancelButtonText} = acItem;
  let msgText = "";
  if (typeof msg === "string") {
    msgText = msg;
  }
  if (typeof msg === "function") {
    msgText = msg(data);
  }
  if (acItem.funcType === "confirm") {
    ElMessageBox.confirm(msgText, tips || "提示", {
      confirmButtonText: confirmButtonText || "确定",
      cancelButtonText: cancelButtonText || "取消",
      customClass: "confirmModal",
      cancelButtonClass: "cancel-btn",
      confirmButtonClass: "confirm-btn",
    })
        .then(() => {
          acItem.onClick(data);
        })
        .catch(() => {
        });
  } else {
    acItem.onClick(data);
  }
};

// 文字
const handleText = (text = "", row) => {
  if (typeof text === "string") {
    return text;
  }
  if (typeof text === "function") {
    return text(row);
  }
  return String(text);
};

// 切换展开收缩
const tableRef = ref(null);
const toogleExpand = (record) => {
  tableRef.value.toggleRowExpansion(record);
};
// 展开或者关闭触发
const handleExpandChange = (row, expandedRows) => {
  emit("expand-change", row, expandedRows);
};
// 当前行是否展开
const isRowExpanded = (index, key = 'id') => {
  if (tableRef.value && tableRef.value.store) {
    const store = tableRef.value.store;
    const id = tableData[index][key]
    const arrKey = store.states.expandRows._rawValue.map(item => item[key])
    // 返回当前行是否展开状态
    return arrKey.indexOf(id) != -1
  } else {
    return false;
  }
}
// 暴露出去的子组件方法
defineExpose({toogleExpand, doQuery, isRowExpanded});
</script>
<style lang="scss" scoped>
.table-content {
  border: solid #eaeaea 1px;

  :deep(.table-real) {
    --el-table-border-color: #eaeaea;
    --el-table-header-bg-color: #fafafa;
    --el-table-header-text-color: rgba(0, 0, 0, 0.8);
    --el-table-row-hover-bg-color: #fafafa;

    .el-table__expand-column {
      & + .el-table__cell {
        .cell {
          &::before {
            width: unset !important;
          }
        }
      }
    }

    .el-table__cell {
      padding: 18px 0;

      + th.el-table__cell {
        .cell {
          position: relative;

          &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            content: "";
            height: 22px;
            width: 1px;
            background: #eaeaea;
          }
        }
      }
    }
  }

  :deep(.is-link) {
    &:hover {
      --el-button-hover-link-text-color: #f31409;
    }
  }

  :deep(.is-disabled) {
    --el-color-danger-light-5: #c81033;
    opacity: 0.2;
  }

  :deep(.el-table__expand-icon) {
    display: none;
  }

  .btn-group {
    display: flex;
    align-items: center;
    font-size: 0;

    button {
      & + button {
        position: relative;

        &:before {
          content: '';
          left: -7px;
          display: block;
          width: 1px;
          height: 12px;
          background: #eaeaea;
          position: absolute;
        }
      }
    }
  }
}
</style>