<template>
  <el-upload
      :on-success="handleSuccess"
      :before-upload="handleBeforeUpload"
      :show-file-list="false"
      :action="action"
      :headers="{Token}"
      :file-list="file"
      :accept="accept"
      v-bind="uploaderProps"
  >
    <div class="uploader" :style="{ width: size, height: size }">
      <img :src="require('@/assets/icon_plus.png')" alt=""/>
    </div>
  </el-upload>
</template>

<script setup>
import {ElNotification} from 'element-plus';
import {useRouter} from "vue-router";
import {computed} from "vue";
import cache from "@/utils/cache";
import {showElMessage} from "@/utils/element";

const router = useRouter();

const Token = cache.getItem('token')
const props = defineProps({
  // 上传路径
  action: {
    type: String,
    default: `${process.env.VUE_APP_URL}/attachment/upload2`
  },
  width: {
    type: Number,
  },
  height: {
    type: Number,
  },
  // 自定义属性
  uploaderProps: {
    type: Object,
    default: () => {
      return {};
    },
  },
  modelValue: {
    type: [Array, String],
    default: () => []
  },
  // 大小
  size: {
    type: String,
    default: "98px",
  },
  accept: {
    type: String,
    default: 'image/png,image/jpg'
  },
  // 图片大小
  mb: {
    type: Number,
    default: 5
  }
});
const file = computed(() => {
  let list = []
  if (props.modelValue) {
    if (typeof props.modelValue === 'string') {
      list = [{uid: props.modelValue}]
    } else {
      list = props.modelValue.map(item => {
        return {
          uid: item
        }
      })
    }
  }
  return list
})
const $emit = defineEmits(['update:modelValue'])
// 上传成功回调
const handleSuccess = (e, file, fileList) => {
  if (fileList.every((item) => item.status == "success")) {
    if (e.error) {
      ElNotification.warning(e.message);
      if (e.code == 300) {
        router.replace({
          path: '/login'
        })
      }
    } else {
      const arr = fileList.map(item => {
        if (item.response) {
          return item.response[0].id
        } else {
          return item.uid
        }
      })
      $emit('update:modelValue', props.uploaderProps.limit === 1 ? arr[0] : arr)
    }
  }
};
// 上传前回调
const handleBeforeUpload = (file) => {
  const isFormat = props.accept.includes(file.type);
  const isMb = file.size / 1024 / 1024 <= props.mb;
  if (!isFormat) {
    showElMessage({
      type: 'warning',
      message: '图片不符合上传要求,请重新上传'
    })
    return false
  } else if (!isMb) {
    showElMessage({
      type: 'warning',
      message: '图片不符合上传要求,请重新上传'
    })
    return false
  } else {
    let isSize=true;
    if(props.width&&props.height){
       isSize = new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          const valid = img.width == props.width && img.height == props.height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          () => {
            showElMessage({
              type: 'warning',
              message: '图片不符合上传要求,请重新上传'
            })
            return Promise.reject();
          },
      );
    }
    return isFormat && isMb && isSize
  }
};
</script>

<style lang="scss" scoped>
.uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 98px;
  background: #fff;
  border: dashed #eaeaea 1px;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.9);
  }

  > img {
    width: 18px;
    height: 18px;
  }
}
</style>