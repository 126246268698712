import operation from './operation';
import content from './content';
import base from './base';
import dataShow from "@/router/dataShow";
import auth from "@/router/auth";

const routes = [
    ...dataShow,
    ...base,
    ...operation,
    ...content,
    ...auth
]

export default routes