const dataShow = [
    {
        path: '/auth',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: '权限管理',
            isMenu: true,
            icon: 'icon_auth.png',
        },
        redirect:'/auth/list',
        children: [
            {
                path:'/auth/list',
                component: () => import('@/views/auth/index.vue'),
                meta: {
                    title: '权限管理',
                    isMenu: true,
                },
            }
        ]
    }
]

export default dataShow