<template>
  <div class="commonTabs">
    <el-tabs :model-value="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tags"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      >
      </el-tab-pane>
    </el-tabs>
    <slot name="right"></slot>
  </div>
</template>

<script setup>
// props
defineProps({
  // 列表
  tags: {
    type: Array,
    default: () => {
      return [];
    },
  },
  // 激活项
  activeName: {
    type: String,
    default: "",
  },
});

// emit
const emit = defineEmits(["tab-click"]);
const handleClick = (e) => {
  emit("tab-click", e);
};
</script>

<style lang="scss" scoped>
.commonTabs {
  position: relative;
}

:deep(.el-tabs__item) {
  --el-font-size-base: 16px;
  --el-text-color-primary: rgba(0, 0, 0, 0.4);

  &:hover {
    --el-color-primary: rgba(0, 0, 0, 0.8);
  }

  &.is-active {
    --el-color-primary: rgba(0, 0, 0, 0.8);
  }
}

:deep(.el-tabs__nav-wrap) {
  &::after {
    --el-border-color-light: #eaeaea;
    height: 1px;
  }
}
</style>