import {ElMessage, ElNotification} from 'element-plus'

export function showElNotification({message,type='warning'}) {
    const notification=document.querySelector('.el-notification');
    if(!notification){
        ElNotification({
            title: '温馨提示',
            message,
            type,
        })
    }
}

export function showElMessage(option) {
    ElMessage(option)
}