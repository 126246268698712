<template>
  <div class="table-filter" v-if="filterConfig.filterList.length">
    <el-row :gutter="filterConfig.gutter">
      <el-col
          v-for="item in filterConfig.filterList"
          :key="item.prop"
          :span="item.span || 8"
          :offset="item.offset"
      >
        <div class="formGroup">
          <el-select
              v-if="['preSelect'].includes(item.type)"
              v-model="filterData[item.tagsType]"
              @change="e=>selectChange(e,item)"
              :placeholder="item.placeholder || '请选择'"
              class="preSelect"
          >
            <el-option
                v-for="item in item.optionPreList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span
              v-if="['input', 'select', 'datePicker'].includes(item.type)"
              class="prepend"
          >{{ item.label }}</span
          >
          <el-date-picker
              v-if="['datePicker'].includes(item.type)"
              @change="handleQuery"
              value-format="x"
              v-model="filterData[item.prop]"
              v-bind="item.props"
          />
          <el-input
              v-if="['input'].includes(item.type)"
              v-model="filterData[item.prop]"
              :placeholder="item.placeholder || '请输入'"
              class="flexInput"
          />
          <el-select
              v-if="['select', 'preSelect'].includes(item.type)"
              v-model="filterData[item.prop]"
              @change="handleQuery"
              :placeholder="item.placeholder || '请选择'"
              class="flexInput"
              :multiple="item.multiple"
              :clearable="item.clearable"
              :collapseTags="item.collapseTags"
          >
            <el-option
                v-for="item in item.optionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span
              v-if="['input'].includes(item.type)"
              @click="handleQuery"
              class="icon-table-search"
          ></span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// props
import {onMounted} from "vue";

const props=defineProps({
  // 表单配置
  filterConfig: {
    type: Object,
    required: true,
    default: () => {
      return {
        gutter:12,
        // 这里是表单配置项
        filterList:[]
      }
    },
  },
  // 值
  filterData: {
    type: Object,
    required: true,
    default: () => {},
  },
});

// emit
const emit = defineEmits(["query"]);
const handleQuery = () => {
  emit("query");
};

const selectChange=(e,item)=>{
  item.selectChange(e);
  handleQuery()
}
onMounted(()=>{
  props.filterConfig.filterList.forEach((k)=>{
    if (k.query) {
      k.query().then(data=>{
        k.optionList=data;
      });
    }
  })
})
</script>

<style lang="scss" scoped>
.table-filter {
  padding: 24px 0 0;
}
.formGroup {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  .prepend {
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: solid #e5e6ea 1px;
    border-right: none;
    background: #fafafa;
    box-sizing: border-box;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .preSelect {
    max-width: 104px;
    :deep(.el-select__wrapper) {
      --el-fill-color-blank: #fafafa;
    }
  }
  .flexInput {
    flex: 1;
  }
  .icon-table-search {
    width: 41px;
    height: 40px;
    background: url("~@/assets/icon_search.png") center center no-repeat;
    background-size: 14px 14px;
    border: solid #e5e6ea 1px;
    border-left: none;
    box-sizing: border-box;
    cursor: pointer;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

:deep(.el-input__wrapper) {
  border-radius: 0;
}
:deep(.el-select__wrapper) {
  border-radius: 0 2px 2px 0;
}
</style>