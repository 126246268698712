/**
 * vuex
 * 在modules文件夹下创建相应的文件，无需其他操作
 * **/

import {createStore} from "vuex";
import cache from "@/utils/cache";
const path = require("path");

const files = require.context("./modules", false, /\.js/);
const modules = {};
files.keys().forEach((key) => {
  const name = path.basename(key, ".js");
  modules[name] = files(key).default;
});
console.log(modules)
const store = createStore({
  modules
});

/**
 * 初始化vuex数据
 **/
export function setupStore() {
  store.commit('user/changeUser',cache.getItem('user')||null)
}

export default store;
