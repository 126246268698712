const base = [
    {
        path: '/login',
        component: () => import('@/views/base/index.vue'),
        meta:{
            isMenu: false,
        }
    }
]

export default base