import dayjs from "dayjs";

const format = function (date, format = "YYYY-MM-DD HH:mm") {
    if (date===null || date===undefined) {
        return '';
    }
    return dayjs(date).format(format);
}

const DateFormat = {
    format,

    datetime(date) {
        return format(date, "YYYY-MM-DD HH:mm:ss")
    },

    time(date) {
        return format(date, "HH:mm:ss")
    },

    date(date) {
        return format(date, "YYYY-MM-DD")
    },
    dateDot(date) {
        return format(date, 'YYYY.MM.DD');
    },
    dateDot2(date) {
        return format(date, 'MM.DD');
    },
}

export default DateFormat