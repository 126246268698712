
import cache from "@/utils/cache";
const user = {
  namespaced: true,
  state: {
    user:null
  },
  mutations: {
    changeUser(state,user){
      if(user){
        cache.setItem('token',user.token)
      }
      cache.setItem('user',user)
      state.user=user
    }
  },
  getters: {},
  actions: {

  },
};
export default user;
